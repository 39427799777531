import React from "react";
import Services from "../Services.js/Services";

const ServicesInner = () => {
  return (
    <div>
      <Services />
    </div>
  );
};

export default ServicesInner;
